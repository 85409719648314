import React from "react";
import { Link } from "react-router-dom";
import { Map } from "../";

export class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", submitted: false };
  }

  submitContactInformation = async () => {
    let response = await fetch(process.env.REACT_APP_API + "/bat/contact/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({ email: this.state.email }),
    });

    if (response.ok) {
      this.setState({ submitted: true });
    }
  };

  render() {
    return (
      <div>
        <div
          class="z-0 opacity-90 w-full absolute"
          style={{
            height: "780px",
            background:
              "linear-gradient(-45deg, #3B5EC8 0%, #53a0fd 50%, #51eca5 100%)",
          }}
        ></div>
        <div class="absolute w-full flex flex-col">
          <div class="bg-bottom bg-no-repeat bg-cover">
            <header class="z-20 w-full xl:px-0 px-2">
              <div class="container mx-auto flex flex-wrap mt-8 mb-8 ">
                <div class="flex sm:w-1/2 flex">
                  <img
                    class="h-9 w-9"
                    src="/bat_icon_rounded.png"
                    alt="Mobile phone"
                  />
                  <a href="/" class="text-2xl text-white ml-1 font-bold">
                    What the mask
                  </a>
                </div>
                <div class="w-1/2 sm:flex justify-end">
                  <a href="https://play.google.com/store/apps/details?id=be.whatthemask&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      className="h-20"
                      alt="Ontdek het op Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/nl_badge_web_generic.png"
                    />
                  </a>
                  <a
                    class="border border-white bg-transparent text-white uppercase rounded-full py-2 px-4 hover:bg-blue-600 h-10 mt-4"
                    href="https://github.com/iPieter/what-the-mask-ios"
                  >
                    Fork voor iOS
                  </a>
                </div>
              </div>
            </header>
            <section class="w-full">
              <div class="container mx-auto sm:items-center  md:flex md:flex-row xl:pb-16 xl:px-0 px-4">
                <div class="md:w-1/2 text-white flex flex-col">
                  <img
                    class="h-40 w-40 mb-10"
                    src="/bat_icon_rounded.png"
                    alt="Mobile phone"
                  />
                  <h1 class="text-5xl font-weight-bolder sm:mb-4">
                    What the mask
                  </h1>

                  <p class="text-base">
                    Waar moet je een mondmasker dragen en waar niet? Welke
                    regels gelden waar? 'What the mask' geeft een handig
                    overzicht voor de huidige regels in Vlaanderen. Kom je in
                    een mondmaskerzone, dan krijg je een melding van de app. Be
                    safe, wear your mask!
                  </p>

                  <div class="flex xl:flex mt-8 mb-32">
                    <input
                      class="rounded-l-full p-4 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white"
                      placeholder="your@mail.com"
                      value={this.state.email}
                      onChange={(event) =>
                        this.setState({
                          email: event.target.value,
                        })
                      }
                    />
                    <button
                      class="px-8 rounded-r-full text-white font-bold p-4 uppercase border-white border-t border-b border-r"
                      href="#"
                      onClick={() => this.submitContactInformation()}
                    >
                      {this.state.submitted ? "Ok!" : "Hou me op de hoogte"}
                    </button>
                  </div>
                </div>
                <div class="md:w-1/2 w-full xl:pt-8 xl:items-center flex justify-center">
                  <img
                    class="md:w-3/5 h-full w-1/2"
                    src="/screenshot.png"
                    alt="Mobile phone"
                  />
                </div>
              </div>
            </section>
          </div>

          <div class="bg-white pb-0 w-full pt-12">
            <div class="container mx-auto">
              <div class="text-center w-full pb-12">
                <h3 class="text-2xl text-gray-800 text-blue-500 font-medium"></h3>
                <p class="sm:text-3xl text-2xl text-black-800 pt-4 xl:w-1/2 mx-auto">
                  'What the mask' geldt voor heel Vlaanderen
                </p>
              </div>
              <div class="rounded shadow-xl mb-24">
                <Map></Map>
              </div>
            </div>
          </div>

          <div class="bg-white">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 items-center flex flex-col">
              <div class="text-center w-full pb-12">
                <p class="sm:text-3xl text-2xl text-black-800 pt-4">
                  Overtuigd? Download dan nu de app voor Android.
                </p>
              </div>
              <a
                className=""
                href="https://play.google.com/store/apps/details?id=be.whatthemask&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  className="h-20"
                  alt="Ontdek het op Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/nl_badge_web_generic.png"
                />
              </a>
            </div>
          </div>

          <div class="bg-gray-50">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 class="text-3xl font-medium tracking-tight text-gray-900 sm:text-4xl">
                <span class="block">
                  Heb je een iPhone en wil je 'What the mask' uitproberen?
                </span>
                <span class="block text-blue-500 mt-1">
                  Test dan nu de Beta-versie
                </span>
              </h2>
              <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                <div class="inline-flex rounded-md">
                  <a
                    href="https://github.com/iPieter/what-the-mask-ios"
                    class="rounded-full p-4 border mr-0 border-gray-200 bg-white text-blue-500 font-bold p-4 uppercase"
                  >
                    Bekijk op Github
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="bg-white pb-0 w-full pt-12">
            <div class="container mx-auto">
              <div class="flex sm:flex-row flex-col w-full xl:mt-12 mb-0 xl:px-0 px-4">
                <div class="sm:w-1/3 mb-0 sm:mb-0 xl:pr-0 pr-2">
                  <div class="flex flex-wrap mb-4">
                    <div class="">
                      <h4 class="text-black items-center xl:text-2xl">
                        Up to date, zelfs zonder de app te openen
                      </h4>
                    </div>
                  </div>
                  <p class="px-1">
                    'What the mask' kan notificaties sturen wanneer je een
                    mondmaskerzone binnen komt of buiten gaat. Zo hoef je zelfs
                    niet de app te openen. Handig!
                  </p>
                  <img
                    class="w-3/4 justify-center mx-auto mb-0"
                    src="/screenshot_notification.png"
                    alt="Mobile phone"
                  />
                </div>
                <div class="sm:w-1/3 mb-0 sm:mb-0 xl:pr-0 pr-2">
                  <div class="flex flex-wrap mb-4">
                    <div class="">
                      <h4 class="text-black items-center xl:text-2xl">
                        Een kluwen aan regels, samengevat in je broekzak.
                      </h4>
                    </div>
                  </div>
                  <p class="px-1">
                    Bekijk{" "}
                    <Link to="cities" class="text-blue-600 hover:underline">
                      hier
                    </Link>{" "}
                    de lijst met steden en gemeentes die wij ondersteunen.
                  </p>
                </div>
                <div class="sm:w-1/3 mb-0 sm:mb-0 xl:pr-0 pr-2">
                  <div class="flex flex-wrap mb-4">
                    <div class="">
                      <h4 class="text-black items-center xl:text-2xl">
                        Altijd de laatste informatie.
                      </h4>
                    </div>
                  </div>
                  <p class="px-1">
                    Wij houden de lokale wetgeving in de gaten en passen de
                    mondmaskerszones aan. Wanneer jij 'What the mask' opent
                    worden de nieuwste zones automatisch gedownload, zo ben je
                    altijd up to date, zonder zorgen.
                  </p>
                  <img
                    class="w-3/5 justify-center mx-auto mb-0 mt-12"
                    src="/sync.png"
                    alt="sync"
                  />
                </div>
              </div>
            </div>
          </div>
          <footer class="w-full text-center pb-12 border-t border-gray-200 pt-0 text-xs text-gray-700 pt-4">
            <div>
              Copyright Pieter Delobelle, Birthe van den Berg en Gert-Jan Bottu,
              2021 •{" "}
              <a
                href="mailto:team@whatthemask.be"
                class="text-blue-600 hover:underline"
              >
                Contact »
              </a>{" "}
              •{" "}
              <Link to="login" class="text-blue-600 hover:underline">
                Admin »{" "}
              </Link>
              •{" "}
              <Link to="privacy" class="text-blue-600 hover:underline">
                Privacy policy »
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
