import React from "react";
import "./home.css";
import L from "leaflet";
import {} from "mapbox-gl-leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      geojson: {},
      map: {},
      saved: true,
      feature: null,
      updateFeature: null,
    };
  }

  async componentDidMount() {
    const map = L.map(this.ref.current);

    // the attribution is required for the Geoapify Free tariff plan
    map.attributionControl
      .setPrefix("What the mask")
      .addAttribution(
        'Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
      );

    map.pm.addControls({
      position: "topright",
    });

    let _this = this;

    function onEachFeature(feature, layer) {
      //bind click
      console.log(feature);
      layer.on({
        click: (e) => {
          console.log(feature);
          _this.setState({ feature: feature.properties });
          _this.setState({
            updateFeature: (f) => {
              console.log(f);
              _this.setState({ saved: false });
              feature.properties = f;
            },
          });
        },
      });
    }

    //map.on("pm:vertexadded", (e) => this.setState({ saved: false }));
    //map.on("pm:vertexremoved", (e) => this.setState({ saved: false }));
    //map.on("pm:markerdrag", (e) => this.setState({ saved: false }));
    map.on("pm:remove", (e) => this.setState({ saved: false }));
    //map.on("pm:drag", (e) => this.setState({ saved: false }));

    // enable drawing mode for shape - e.g. Poly, Line, Circle, etc
    //map.pm.enableDraw("Polygon", options);
    let response = await fetch(
      process.env.REACT_APP_API + "/bat/policy/zones.geojson"
    );

    if (response.ok) {
      // if HTTP-status is 200-299
      // get the response body (the method explained below)
      let geojson = await response.json();
      this.setState({ geojson });

      const layer = L.geoJSON(geojson, { onEachFeature: onEachFeature });
      layer.addTo(map);

      var myAPIKey = process.env.REACT_APP_MAPBOX;
      const mapStyle =
        "https://maps.geoapify.com/v1/styles/klokantech-basic/style.json";

      const gl = L.mapboxGL({
        style: `${mapStyle}?apiKey=${myAPIKey}`,
        accessToken: "no-token",
      }).addTo(map);

      map.fitBounds(layer.getBounds());

      layer.on("pm:edit", (e) => this.setState({ saved: false }));

      this.setState({ map });
    } else {
      alert("HTTP-Error: " + response.status);
    }
  }

  renderActionButton() {
    if (this.state.saved) {
      return (
        <button
          class="left-20 bottom-2 absolute ml-4 leaflet-control-zoom leaflet-bar leaflet-control bg-white hover:bg-gray-100 text-gray-600 font-semibold py-2 px-4 mx-2 border border-gray-400 rounded"
          style={{ zIndex: 1000 }}
          href="#"
          onClick={() => this.saveGeojson()}
        >
          <div class="inline-block">
            <FontAwesomeIcon
              icon={faCheckCircle}
              class="w-5 h-5 inline-block text-green-500"
            />
            <span class="inline-block ml-1">saved</span>
          </div>
        </button>
      );
    } else {
      return (
        <button
          class="left-20 bottom-2 absolute ml-4 leaflet-control-zoom leaflet-bar leaflet-control hover:bg-gray-200 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded border border-blue-500"
          style={{ zIndex: 1000 }}
          href="#"
          onClick={() => this.saveGeojson()}
        >
          <div class="inline-block">
            <FontAwesomeIcon icon={faSave} class="w-6 h-6 inline-block" />
            <span class="inline-block ml-1">save</span>
          </div>
        </button>
      );
    }
  }

  renderEditor() {
    return (
      <div
        class="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800 bg-opacity-75"
        style={{ zIndex: "9999" }}
      >
        <div class="bg-white rounded-lg w-1/3">
          <div class="flex flex-col items-start p-4">
            <div class="flex items-center w-full mb-2">
              <div class="text-gray-900 font-bold text-lg ">
                Edit polygon properties
              </div>
              <svg
                class="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </div>
            <hr />
            <div class="w-full">
              <div class="-mx-3 md:flex mb-6 w-full">
                <div class="w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                    for="grid-area-name"
                  >
                    Area name
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                    id="grid-area-name"
                    type="text"
                    placeholder="Gent centrum"
                    value={this.state.feature.name}
                    onChange={(event) => {
                      this.setState({
                        feature: {
                          ...this.state.feature,
                          name: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div class="w-1/2 px-3">
                  <label
                    class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                    for="grid-postal-code"
                  >
                    Postal code
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                    id="grid-postal-code"
                    type="text"
                    placeholder="9000"
                    value={this.state.feature.area_code}
                    onChange={(event) => {
                      this.setState({
                        feature: {
                          ...this.state.feature,
                          area_code: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div class="-mx-3 md:flex mb-6 w-full">
                <div class="w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                    for="grid-start"
                  >
                    Start hour
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-3"
                    id="grid-start"
                    type="number"
                    placeholder="9"
                    value={this.state.feature.start}
                    onChange={(event) => {
                      this.setState({
                        feature: {
                          ...this.state.feature,
                          start: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div class="w-1/2 px-3">
                  <label
                    class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
                    for="grid-end"
                  >
                    End hour
                  </label>
                  <input
                    class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                    id="grid-end"
                    type="number"
                    placeholder="18"
                    value={this.state.feature.end}
                    onChange={(event) => {
                      this.setState({
                        feature: {
                          ...this.state.feature,
                          end: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="ml-auto">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mx-2 rounded border border-blue-500"
                onClick={() => {
                  this.state.updateFeature(this.state.feature);
                  this.setState({ feature: null });
                }}
              >
                save
              </button>
              <button
                class="bg-transparent hover:bg-gray-100 text-blue-500 font-semibold py-2 px-4 mx-2 border border-blue-500 rounded"
                onClick={() => this.setState({ feature: null })}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMapView() {
    return <div className="map-container h-full w-full" ref={this.ref}></div>;
  }

  saveGeojson = async () => {
    var layers = L.PM.Utils.findLayers(this.state.map);
    var group = L.featureGroup();
    layers.forEach((layer) => {
      group.addLayer(layer);
    });
    let shapes = group.toGeoJSON();
    console.log(shapes);
    let response = await fetch(process.env.REACT_APP_API + "/bat/policy/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Token " + (await localStorage.getItem("authID")),
      },
      body: JSON.stringify(shapes),
    });

    if (response.ok) {
      this.setState({ saved: true });
    }
  };

  render() {
    return (
      <div>
        <div class="flex flex-row h-full">
          <nav class="bg-gray-900 w-20  justify-between flex flex-col ">
            <div class="mt-5 mb-10">
              <a href="#">
                <img
                  src="/bat_icon_rounded.png"
                  class="w-14 h-14 mb-1 mx-auto"
                />
              </a>
              <div class="mt-10">
                <ul>
                  <li class="mb-6">
                    <a href="#">
                      <span>
                        <svg
                          class="fill-current h-5 w-5 text-gray-300 mx-auto hover:text-green-500 "
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14 3V3.28988C16.8915 4.15043 19 6.82898 19 10V17H20V19H4V17H5V10C5 6.82898 7.10851 4.15043 10 3.28988V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3ZM7 17H17V10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10V17ZM14 21V20H10V21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li class="mb-6">
                    <a href="#">
                      <span>
                        <svg
                          class="fill-current h-5 w-5 mx-auto text-gray-300 hover:text-green-500"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M12 4a4 4 0 014 4 4 4 0 01-4 4 4 4 0 01-4-4 4 4 0
                            014-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4
                            8-4z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li class="mb-6">
                    <a href="#">
                      <span>
                        <svg
                          class="fill-current h-5 w-5 text-gray-300 mx-auto hover:text-green-500"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M12 13H7v5h5v2H5V10h2v1h5v2M8
                            4v2H4V4h4m2-2H2v6h8V2m10 9v2h-4v-2h4m2-2h-8v6h8V9m-2
                            9v2h-4v-2h4m2-2h-8v6h8v-6z"
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span>
                        <svg
                          class="fill-current h-5 w-5 text-gray-300 mx-auto hover:text-green-500"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22.775 8C22.9242 8.65461 23 9.32542 23 10H14V1C14.6746 1 15.3454 1.07584 16 1.22504C16.4923 1.33724 16.9754 1.49094 17.4442 1.68508C18.5361 2.13738 19.5282 2.80031 20.364 3.63604C21.1997 4.47177 21.8626 5.46392 22.3149 6.55585C22.5091 7.02455 22.6628 7.5077 22.775 8ZM20.7082 8C20.6397 7.77018 20.5593 7.54361 20.4672 7.32122C20.1154 6.47194 19.5998 5.70026 18.9497 5.05025C18.2997 4.40024 17.5281 3.88463 16.6788 3.53284C16.4564 3.44073 16.2298 3.36031 16 3.2918V8H20.7082Z"
                            fill="currentColor"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1 14C1 9.02944 5.02944 5 10 5C10.6746 5 11.3454 5.07584 12 5.22504V12H18.775C18.9242 12.6546 19 13.3254 19 14C19 18.9706 14.9706 23 10 23C5.02944 23 1 18.9706 1 14ZM16.8035 14H10V7.19648C6.24252 7.19648 3.19648 10.2425 3.19648 14C3.19648 17.7575 6.24252 20.8035 10 20.8035C13.7575 20.8035 16.8035 17.7575 16.8035 14Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="mb-4">
              <a>
                <span>
                  <svg
                    class="fill-current h-5 w-5 text-gray-300 mx-auto hover:text-red-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 4.00894C13.0002 3.45665 12.5527 3.00876 12.0004 3.00854C11.4481 3.00833 11.0002 3.45587 11 4.00815L10.9968 12.0116C10.9966 12.5639 11.4442 13.0118 11.9965 13.012C12.5487 13.0122 12.9966 12.5647 12.9968 12.0124L13 4.00894Z"
                      fill="currentColor"
                    />
                    <path
                      d="M4 12.9917C4 10.7826 4.89541 8.7826 6.34308 7.33488L7.7573 8.7491C6.67155 9.83488 6 11.3349 6 12.9917C6 16.3054 8.68629 18.9917 12 18.9917C15.3137 18.9917 18 16.3054 18 12.9917C18 11.3348 17.3284 9.83482 16.2426 8.74903L17.6568 7.33481C19.1046 8.78253 20 10.7825 20 12.9917C20 17.41 16.4183 20.9917 12 20.9917C7.58172 20.9917 4 17.41 4 12.9917Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </a>
            </div>
          </nav>
          <div class="text-gray-700 bg-gray-200 h-screen w-screen">
            {this.renderMapView()}
          </div>
          <div>{this.renderActionButton()}</div>
        </div>
        {this.state.feature != null ? this.renderEditor() : <div></div>}
      </div>
    );
  }
}
