import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export class CitiesSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
    };
  }

  async componentDidMount() {
    let response = await fetch(
      process.env.REACT_APP_API + "/bat/policy/zones.geojson"
    );

    if (response.ok) {
      let geojson = await response.json();
      console.log(geojson);

      this.setState({
        cities: geojson.features.map((feature) => ({
          name: feature.properties.name,
          start: feature.properties.start,
          end: feature.properties.end,
        })),
      });
    } else {
      alert("HTTP-Error: " + response.status);
    }
  }

  renderList() {
    return this.state.cities.map((f) => {
      return (
        <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
          <FontAwesomeIcon
            icon={faCheckCircle}
            class="text-green-500 h-4 w-4 m-0.5 inline-block"
          />{" "}
          <div class="flex-grow font-medium px-2">
            {f.name ? f.name : "Onbekend gebied"}
          </div>
          <div class="text-sm font-normal text-gray-500 tracking-wide">
            {f.start}
            {f.start ? "h - " : ""}
            {f.end}
            {f.end ? "h" : ""}
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div>
        <div
          class="z-0 opacity-90 w-full absolute h-24"
          style={{
            background:
              "linear-gradient(-45deg, #3B5EC8 0%, #53a0fd 50%, #51eca5 100%)",
          }}
        ></div>
        <div class="absolute w-full flex flex-col">
          <div class="bg-bottom bg-no-repeat bg-cover">
            <header class="z-20 w-full xl:px-0 px-2">
              <div class="container mx-auto flex flex-wrap mt-8 mb-8 ">
                <div class="flex sm:w-1/2 flex">
                  <img
                    class="h-9 w-9"
                    src="/bat_icon_rounded.png"
                    alt="Mobile phone"
                  />
                  <a href="/" class="text-2xl text-white ml-1 font-bold">
                    What the mask
                  </a>
                </div>
                <div class="w-1/2 sm:flex justify-end">
                  <a
                    class="border border-white bg-transparent text-white uppercase rounded-full py-2 px-4 hover:bg-blue-600"
                    href="https://github.com/iPieter/what-the-mask"
                  >
                    Fork op Github
                  </a>
                </div>
              </div>
            </header>
          </div>
          <div class="bg-white pb-0 w-full pt-12">
            <div class="container mx-auto">
              <div class="flex sm:flex-row flex-col w-full xl:mt-12 mb-0 xl:px-0 px-4">
                <div class="sm:w-1/4 mb-0 sm:mb-0 xl:pr-0 pr-2"></div>
                <div class="sm:w-1/2 mb-0 sm:mb-0 xl:pr-0 pr-2">
                  <div class="flex flex-wrap mb-4">
                    <div class="text-center w-full text-gray-700">
                      <h1 class="sm:text-5xl text-xl font-weight-bolder sm:mb-4">
                        What the mask
                      </h1>
                      <h2 class="sm:text-2xl text-base mb-2">
                        Ondersteunde steden en gemeentes
                      </h2>
                    </div>
                  </div>

                  <div class="flex items-center bg-gray-100 rounded-md">
                    <div class="pl-2">
                      <svg
                        class="fill-current text-gray-500 w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          class="heroicon-ui"
                          d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                        />
                      </svg>
                    </div>
                    <input
                      class="w-full rounded-md bg-gray-100 text-gray-700 leading-tight focus:outline-none py-2 px-2"
                      id="search"
                      type="text"
                      placeholder="Zoek naar jouw gemeente"
                    />
                  </div>
                  <div class="py-3 text-sm">{this.renderList()}</div>
                </div>
              </div>
            </div>
          </div>
          <footer class="w-full text-center pb-12 border-t border-gray-200 pt-0 text-xs text-gray-700 pt-4">
            <div>
              Copyright Pieter Delobelle en Birthe van den Berg, 2021 •{" "}
              <Link to="login" class="text-blue-600 hover:underline">
                Admin »
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
