import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthRoute, MainRoute } from "./routes";
import { Home, Login, SignUp, Landing, CitiesSupport, Privacy } from "./pages";

function AppRoute() {
  const [isAuthenticated, setisAuthenticated] = useState(
    Boolean(localStorage.getItem("authID"))
  );

  useEffect(() => {
    setisAuthenticated(Boolean(localStorage.getItem("authID")));
  }, [isAuthenticated]);

  return (
    <Router>
      <Switch>
        {/* auth route for unauthenticated routes */}

        <AuthRoute
          path="/login"
          Component={Login}
          exact
          isAuthenticated={isAuthenticated}
        />
        <AuthRoute
          path="/signup"
          Component={SignUp}
          exact
          isAuthenticated={isAuthenticated}
        />

        {/* main route for authorized routes */}
        <MainRoute
          path="/map"
          Component={Home}
          exact
          isAuthenticated={isAuthenticated}
        />

        <AuthRoute path="/privacy" Component={Privacy} />
        <AuthRoute path="/cities" Component={CitiesSupport} />
        <AuthRoute path="/" Component={Landing} />
      </Switch>
    </Router>
  );
}

export default AppRoute;
