import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

export class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
    };
  }

  render() {
    return (
      <div>
        <div
          class="z-0 opacity-90 w-full absolute h-24"
          style={{
            background:
              "linear-gradient(-45deg, #3B5EC8 0%, #53a0fd 50%, #51eca5 100%)",
          }}
        ></div>
        <div class="absolute w-full flex flex-col">
          <div class="bg-bottom bg-no-repeat bg-cover">
            <header class="z-20 w-full xl:px-0 px-2">
              <div class="container mx-auto flex flex-wrap mt-8 mb-8 ">
                <div class="flex sm:w-1/2 flex">
                  <img
                    class="h-9 w-9"
                    src="/bat_icon_rounded.png"
                    alt="Mobile phone"
                  />
                  <a href="/" class="text-2xl text-white ml-1 font-bold">
                    What the mask
                  </a>
                </div>
                <div class="w-1/2 sm:flex justify-end">
                  <a
                    class="border border-white bg-transparent text-white uppercase rounded-full py-2 px-4 hover:bg-blue-600"
                    href="https://github.com/iPieter/what-the-mask"
                  >
                    Fork op Github
                  </a>
                </div>
              </div>
            </header>
          </div>
          <div class="bg-white pb-0 w-full pt-12">
            <div class="container mx-auto">
              <div class="flex sm:flex-row flex-col w-full xl:mt-12 mb-0 xl:px-0 px-4">
                <div class="sm:w-1/4 mb-0 sm:mb-0 xl:pr-0 pr-2"></div>
                <div class="sm:w-1/2 mb-0 sm:mb-0 xl:pr-0 pr-2">
                  <div class="flex flex-wrap mb-4">
                    <div class="text-center w-full text-gray-700">
                      <h1 class="sm:text-5xl text-xl font-semibold sm:mb-4">
                        What the mask
                      </h1>
                      <h2 class="sm:text-2xl text-base mb-2">
                        Servicevoorwaarden, Privacy policy & Juridische
                        Kennisgeving
                      </h2>
                      <p>
                        Lees onderstaande tekst zorgvuldig na; ze beschrijft wat
                        u van ons kunt verwachten als u onze services gebruikt
                        en wat wij van u verwachten.
                      </p>
                      <h3 class="text-xl font-semibold mt-5">
                        Niet-toegestane handelingen
                      </h3>
                      <p>
                        Het is niet toegestaan enig deel van What the mask te
                        herdistribueren, te verkopen of een nieuw product of
                        service te maken op basis van What the mask.
                      </p>
                      <h3 class="text-xl font-semibold mt-2">
                        Werkelijke omstandigheden; eigen risico
                      </h3>
                      <p>
                        Indien u de kaartgegevens gebruikt, is het mogelijk dat
                        de Werkelijke omstandighedenafwijken van de
                        kaartresultaten en content. Het is uw eigen
                        verantwoordelijkheid om de omstandigheden in te schatten
                        en te beoordelen. U bent te allen tijde verantwoordelijk
                        voor uw eigen gedrag en de gevolgen daarvan.
                      </p>
                      <h3 class="text-xl font-semibold mt-2">
                        Standaard benamingen
                      </h3>
                      <p>
                        De app gebruikt in zijn API standaard naamgeving,
                        steunend op welgekende internationale standaarden voor
                        benamingen en conventies.
                      </p>
                      <h3 class="text-xl font-semibold mt-2">
                        Privacyverklaring
                      </h3>
                      <p>
                        Deze privacyverklaring geeft weer hoe uw
                        persoonsgegevens verzameld en verwerkt worden. U vindt
                        hier ook alle rechten met betrekking tot uw gegevens. De
                        privacyverklaring kan aangepast worden op basis van
                        wetswijzigingen.
                        <br />
                        <br />
                        Uw gegevens worden verwerkt conform de bepalingen van de
                        Europese Algemene Verordening Gegevensbescherming (AVG)
                        van 24 mei 2016 (beter bekend als General Data
                        Protection Regulation of ‘GDPR’).
                        <br />
                        <br />
                        What the mask gaat niet om Personally Identifiable
                        Information (PII) van de app gebruikers is ontworpen
                        onder het concept van 'Privacy by Design'. De
                        locatiegegevens worden op onze servers opgeslagen maar
                        kunnen enkel teruggebracht worden tot een uniek nummer,
                        dat geen info bevat over uw persoonsgegevens.
                        <br />
                        <br />
                        Uw gegevens zullen nooit gebruikt worden voor
                        commerciele doeleinden.
                      </p>
                      <h3 class="text-xl font-semibold mt-2">
                        Apparaattoestemmingen
                      </h3>
                      <p>
                        Voor een goede werking vraagt de app toestemming tot
                        enkele apparaatgegevens. Nadat toestemming gegeven is,
                        kan u deze op elk moment weer intrekken via de
                        apparaatinstellingen. Let wel op dat het intrekken van
                        toestemmingen het correct functioneren van deze app kan
                        beinvloeden.
                        <br />
                        <br />
                        De toestemmingen die gevraagd worden zijn:
                        <br />
                        <br />
                        1. LOCATIEGEGEVENS
                        <br />
                        De app kan locatiegegevens verzamelen en gebruiken om
                        diensten te verlenen op basis van de locatie van uw
                        apparaat. Dit gebeurt, mits toestemming, doorlopend. De
                        app vraagt om uw locatievoorzieningen te activeren en
                        toestemming voor het gebruik ervan te verlenen.
                        Daarnaast wordt er ook gevraagd toestemming te geven om
                        de locatiegegevens op te vragen wanneer de app niet in
                        gebruik is.
                        <br />
                        <br />
                        2. NOTIFICATIES
                        <br />
                        De app kan notificaties of zogenaamde push berichten
                        sturen.
                      </p>
                      <h3 class="text-xl font-semibold mt-2">
                        Toepassingen van derden
                      </h3>
                      <p>
                        Deze app wordt gedownload via een App Store van derden.
                        Lees de relevante privacyverklaringen van de
                        desbetreffende aanbieders na. What the mask is niet
                        verantwoordelijk voor de verwerking van persoonsgegevens
                        door derden (bvb. externe websites, apps).
                      </p>
                      <h3 class="text-xl font-semibold mt-2">
                        Contactgegevens
                      </h3>
                      <p>
                        Indien u vragen heeft over de verwerking van uw
                        persoonsgegevens, een correctie wilt aangeven of een
                        vraag heeft over deze Servicevoorwaarden, Juridische
                        informatie en privacyverklaring, kan u ons contacteren
                        via{" "}
                        <a
                          href="mailto:team@whatthemask.be"
                          class="text-blue-600 hover:underline"
                        >
                          team@whatthemask.be.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="w-full text-center pb-12 border-t border-gray-200 pt-0 text-xs text-gray-700 pt-4">
            <div>
              Copyright Pieter Delobelle en Birthe van den Berg, 2021 •{" "}
              <Link to="login" class="text-blue-600 hover:underline">
                Admin »
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
