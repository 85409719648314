import React from "react";
import "./map.css";
import L from "leaflet";
import {} from "mapbox-gl-leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export class Map extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      geojson: {},
      map: {},
      saved: true,
    };
  }

  async componentDidMount() {
    const map = L.map(this.ref.current);

    // the attribution is required for the Geoapify Free tariff plan
    map.attributionControl
      .setPrefix("What the mask")
      .addAttribution(
        'Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
      );

    function onEachFeature(feature, layer) {
      //bind click
      console.log(feature);
      layer.on({
        click: (e) => {
          console.log(feature);
          console.log(e);
        },
      });
    }

    //map.on("pm:vertexadded", (e) => this.setState({ saved: false }));
    //map.on("pm:vertexremoved", (e) => this.setState({ saved: false }));
    //map.on("pm:markerdrag", (e) => this.setState({ saved: false }));
    //map.on("pm:drag", (e) => this.setState({ saved: false }));

    // enable drawing mode for shape - e.g. Poly, Line, Circle, etc
    //map.pm.enableDraw("Polygon", options);
    let response = await fetch(
      process.env.REACT_APP_API + "/bat/policy/zones.geojson"
    );

    if (response.ok) {
      // if HTTP-status is 200-299
      // get the response body (the method explained below)
      let geojson = await response.json();
      this.setState({ geojson });

      const layer = L.geoJSON(geojson, { onEachFeature: onEachFeature });
      layer.addTo(map);

      var myAPIKey = process.env.REACT_APP_MAPBOX;
      const mapStyle =
        "https://maps.geoapify.com/v1/styles/klokantech-basic/style.json";

      const gl = L.mapboxGL({
        style: `${mapStyle}?apiKey=${myAPIKey}`,
        accessToken: "no-token",
      }).addTo(map);

      map.fitBounds(layer.getBounds());

      this.setState({ map });
    } else {
      alert("HTTP-Error: " + response.status);
    }
  }

  renderMapView() {
    return <div className="map-container h-full w-full" ref={this.ref}></div>;
  }

  render() {
    return (
      <div class="flex flex-row h-full">
        <div class="text-gray-700 bg-gray-200 h-96 w-screen">
          {this.renderMapView()}
        </div>
      </div>
    );
  }
}
