import React from "react";
import { NavLink } from "react-router-dom";
import "./login.css";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "" };
  }

  loginSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("password", this.state.password);
    formData.append("username", this.state.username);

    let response = await fetch(process.env.REACT_APP_API + "/auth/login/", {
      method: "post",
      body: formData,
    });

    if (response.ok) {
      let key = await response.json();
      console.log(key["key"]);

      localStorage.setItem("authID", key["key"]);
      window.location.replace("/map");
    }
  };

  render() {
    return (
      <div class="grid min-h-screen place-items-center bg-gray-900">
        <div class="rounded-sm w-11/12 p-12 bg-white sm:w-8/12 md:w-1/2 lg:w-5/12">
          <h1 class="text-xl font-semibold">
            What the mask,{" "}
            <span class="font-normal">administrator dashboard</span>
          </h1>
          <form class="mt-6">
            <label
              for="username"
              class="block text-xs font-semibold text-r-600 uppercase"
            >
              Username
            </label>
            <input
              id="username"
              type="text"
              value={this.state.username}
              onChange={(event) =>
                this.setState({ username: event.target.value })
              }
              name="username"
              placeholder="john.doe"
              autocomplete="username"
              class="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
              required
            />
            <label
              for="password"
              class="block mt-2 text-xs font-semibold text-gray-600 uppercase"
            >
              Password
            </label>
            <input
              id="password"
              value={this.state.password}
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              type="password"
              name="password"
              placeholder="********"
              autocomplete="current-password"
              class="block w-full p-3 mt-2 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
              required
            />
            <button
              onClick={this.loginSubmit}
              href="#"
              class="w-full py-3 mt-6 font-medium tracking-widest text-white uppercase bg-gray-900 shadow-lg focus:outline-none hover:bg-gray-800 hover:shadow-none"
            >
              Sign in
            </button>
            <p class="flex justify-between inline-block mt-4 text-xs text-gray-500 cursor-pointer hover:text-black">
              Forgot password?
            </p>
          </form>
        </div>
      </div>
    );
  }
}
